import { DEMO_ACTIVE } from "../shared/web-storage-keys";

export const isFirstTimeUser: () => boolean = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const demoRequested = queryParams.get('demo') === '1';
  const demoActive = sessionStorage.getItem(DEMO_ACTIVE) === 'true';

  if (demoActive) {
    return true;
  }

  if (demoRequested) {
    sessionStorage.setItem(DEMO_ACTIVE, 'true');
    return true;
  }

  return false;
};
